import { PaymentSessionTimer } from '@/entities/payment-session-timer'
import { Typography } from '@/shared/components/typography'
import {
  PAYMENT_FAILED_PAGE,
  PAYMENT_SUCCESS_PAGE,
} from '@/shared/constants/routes'
import { usePaymentContext } from '@/shared/contexts/payment-context'
import { useSearchParams } from '@/shared/contexts/search-params-context'
import { useUserDataSSEEvents } from '@/shared/hooks/use-user-data-sse-events'
import { FC, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router'
import styles from './payment-confirm-qr.module.scss'
import { useMediaContext } from '@/shared/contexts/media-context/media-context'
import { useLanguage } from '@/shared/contexts/language-context'

export const PaymentConfirmQr: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { params } = useSearchParams()
  const { isScreenSm } = useMediaContext()
  const { getField } = useLanguage()

  const { eventSource, disableSessionLeftTimer } = usePaymentContext()

  const handleCompletedEvent = useCallback(() => {
    disableSessionLeftTimer()
    navigate({
      pathname: PAYMENT_SUCCESS_PAGE,
      search: new URLSearchParams(params).toString(),
    })
  }, [])

  const handleFailedEvent = useCallback(() => {
    navigate({
      pathname: PAYMENT_FAILED_PAGE,
      search: new URLSearchParams(params).toString(),
    })
  }, [])

  useUserDataSSEEvents({
    withLogs: true,
    eventSource,
    consumerFailed: handleFailedEvent,
    completed: handleCompletedEvent,
  })

  return (
    <div className={styles['payment-body']}>
      <div className={styles['header']}>
        <Typography
          tag="span"
          fontSize={isScreenSm ? '20' : '35'}
          lineHeight="45"
          letterSpacing="micro"
          color="region-surface-on-surface-contrast-highest"
        >
          {isScreenSm
            ? getField('payment_confirm_qr_mobile_title')
            : getField('payment_confirm_qr_desktop_title')}
        </Typography>
        <PaymentSessionTimer />
      </div>
      <div className={styles['img-wrapper']}>
        <img src={location.state?.code} className={styles['img-qr']} />
      </div>
    </div>
  )
}
